.header {
  border-bottom: $hr-border-color $hr-border-width solid;

  &-logo {
    margin-bottom: 1.5rem;
  }
}

main {
  margin-top: -42px;
}
.jumbotron {
  margin-bottom: 0;
}
.nav-tabs {
  background: $gray-100;
}

.table {
  thead {
    th {
      border-top: 0;
      &:not(:first-child) {
        width: 15%;
      }
    }
  }
  tbody {
    th {
      font-weight: normal;
      vertical-align: middle;
    }
  }
  tfoot {
    th {
      vertical-align: middle;
    }
  }
  label {
    margin-bottom: 0;
  }
}

/* .nav-tabs .nav-link {
  font-weight: $font-weight-bold;
} */


.label-add-number {
  padding-left: $table-cell-padding;
}
.single {
  &-number {
    &-row {
      margin-bottom: 0;
    }
  }
}

.nonres {
  &-add-label {
    span {
      font-weight: bold;
      color: $green;
      font-size: $font-size-lg;
    }

  }
}

select:disabled{
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
}
select:disabled::-ms-expand {
  display: none;
}

/*
  We are using CSS transitions for when the enter and move events
  are triggered for the element that has the `repeated-item` class
*/
.repeated-item.ng-enter, .repeated-item.ng-move {
  transition: all 0.5s linear;
  opacity: 0;
}

/*
  `.ng-enter-active` and `.ng-move-active` are where the transition destination
  properties are set so that the animation knows what to animate
*/
.repeated-item.ng-enter.ng-enter-active,
.repeated-item.ng-move.ng-move-active {
  opacity: 1;
}

.nav-link {
  height:100%;
}

/*
  We are using CSS keyframe animations for when the `leave` event
  is triggered for the element that has the `repeated-item` class
*/
.repeated-item.ng-leave {
  animation: 0.5s my_animation;
}

@keyframes my_animation {
  from { opacity: 1; }
  to   { opacity: 0; }
}
